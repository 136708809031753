import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { AvBillingInfoHelperItem } from './AvBillingInfoHelper.types';
import { I18nText } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../../helpers/Icon';
import { Translate } from '../../../../helpers/Translate';

interface AvBillingInfoHelperProps {
  avBillingInfo: AvBillingInfoHelperItem;
  i18nLabel?: I18nText;
  i18nWarning?: I18nText;
}
function AvBillingInfoHelper({
  avBillingInfo,
  i18nWarning,
  i18nLabel
}: AvBillingInfoHelperProps) {
  const addressInfo = compact([
    avBillingInfo?.city,
    avBillingInfo?.state,
    avBillingInfo?.zipCode,
    avBillingInfo?.country
  ]).join(', ');

  return (
    <>
      {i18nLabel && (
        <div className="font-semibold">
          <Translate id={i18nLabel} />
        </div>
      )}

      {i18nWarning && isEmpty(avBillingInfo) && (
        <div className="text-red-500">
          <Icon
            icon={IconsEnum.EXCLAMATION_CIRCLE}
            className="h-4 w-4 -mt-0.5 mr-1 inline-block"
          />
          <Translate id={i18nWarning} />
        </div>
      )}

      {!isEmpty(avBillingInfo) && (
        <div className="text-gray-600 dark:text-gray-400">
          <div>{avBillingInfo.companyName}</div>
          <div>{avBillingInfo.address}</div>
          <div>{addressInfo}</div>
          <div>{avBillingInfo.contactEmail}</div>
          <div>{avBillingInfo.contactPhone}</div>
          <div className="h-2" aria-hidden="true" />
          <div>{avBillingInfo.bankDetails}</div>
        </div>
      )}
    </>
  );
}

export default AvBillingInfoHelper;
