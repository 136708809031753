import { useCallback } from 'react';

import { InvoiceID } from '../../invoicesTypes';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';
import { downloadFileFromBffApi } from '../../../../utils/downloadFileFromBffApi';
import { useLocalStorageProxyState } from '../../../../common/hooks/useLocalStorageProxyState';
import {
  InvoiceItemsGroupBy,
  defaultGroupByState,
  itemsGroupByLocalStorageKey
} from '../../invoicesConstants';

export type DownloadInvoiceInput = {
  invoiceId: InvoiceID;
};

function useDownloadInvoice() {
  const [itemsGroupBy] = useLocalStorageProxyState<
    Record<InvoiceItemsGroupBy, boolean>
  >(itemsGroupByLocalStorageKey, defaultGroupByState);

  const downloadInvoice = useCallback<(input: DownloadInvoiceInput) => void>(
    (input) => {
      const params = new URLSearchParams();

      params.append(
        'grouping',
        JSON.stringify({
          by_projects: itemsGroupBy[InvoiceItemsGroupBy.PROJECTS],
          by_tasks: itemsGroupBy[InvoiceItemsGroupBy.TASKS],
          by_items: itemsGroupBy[InvoiceItemsGroupBy.ITEMS]
        })
      );

      const url = InvoiceBffUrl.download(input.invoiceId);

      downloadFileFromBffApi(`${url}?${params}`);
    },
    [itemsGroupBy]
  );

  return { downloadInvoice };
}

export default useDownloadInvoice;
