import {
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  IdFilterType,
  ItemGqlError
} from '../../types';
import { TeamID } from '../teams/teamsTypes';
import {
  UserFullName,
  UserID,
  UserNanoID,
  UserUUID
} from '../users/usersTypes';

export type TeamsUpsalerUUID = UserUUID;
export type TeamsUpsalerNanoID = UserNanoID;
export type TeamsUpsalerFullName = UserFullName;
export type TeamsUpsalerUpsalerID = UserID;
export type TeamsUpsalerTeamID = TeamID;

export type CreateTeamsUpsalerGqlQuery = CreateItemGqlQuery;
export type CreateTeamsUpsalerGqlStatus = CreateItemGqlStatus;

export type DeleteTeamsUpsalerGqlQuery = DeleteItemGqlQuery;
export type DeleteTeamsUpsalerGqlStatus = DeleteItemGqlStatus;

export type TeamsUpsalerGqlError = ItemGqlError;

export interface FetchTeamsUpsalersFilters {
  id?: IdFilterType;
  uuid?: IdFilterType;
  upsalerId?: IdFilterType;
  teamId?: IdFilterType;
}
export type FetchTeamsUpsalersSort = FetchItemsSort;
export type FetchTeamsUpsalersPage = FetchItemsPage;
export type FetchTeamsUpsalersLimit = FetchItemsLimit;

export const enum FetchTeamsUpsalersSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}
