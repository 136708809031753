import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { BillingInfoHelperItem } from './BillingInfoHelper.types';
import { I18nText } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../../helpers/Icon';
import { Translate } from '../../../../helpers/Translate';

interface BillingInfoHelperProps {
  billingInfo: BillingInfoHelperItem;
  i18nLabel?: I18nText;
  i18nWarning?: I18nText;
}
function BillingInfoHelper({
  billingInfo,
  i18nWarning,
  i18nLabel
}: BillingInfoHelperProps) {
  const addressInfo = compact([
    billingInfo?.city,
    billingInfo?.state,
    billingInfo?.zipCode,
    billingInfo?.country
  ]).join(', ');

  const billingInfoArray = compact([
    billingInfo?.companyName,
    `${billingInfo?.firstName || ''} ${billingInfo?.lastName || ''}`.trim(),
    billingInfo?.address,
    addressInfo,
    billingInfo?.contactPhone,
    billingInfo?.contactEmail
  ]).join(',\n');

  return (
    <>
      {i18nLabel && (
        <div className="font-semibold">
          <Translate id={i18nLabel} />
        </div>
      )}

      {i18nWarning && isEmpty(billingInfo) && (
        <div className="text-red-500">
          <Icon
            icon={IconsEnum.EXCLAMATION_CIRCLE}
            className="h-4 w-4 -mt-0.5 mr-1 inline-block"
          />
          <Translate id={i18nWarning} />
        </div>
      )}

      {!isEmpty(billingInfo) && (
        <div className="text-gray-600 dark:text-gray-400 whitespace-pre-line">
          {billingInfoArray}
        </div>
      )}
    </>
  );
}

export default BillingInfoHelper;
