import cl from 'classnames';

import { Currencies, currenciesSymbols } from '../../../../../../types';
import { CurrencyModalButtonItemType } from '../../../../../../helpers/modalButtons/ChangeCurrencyModalButton/hooks/useChangeCurrencyModalButton';
import { InvoiceItemType } from '../../InvoiceItemsTable';

import { MoneyHelper } from '../../../../../../helpers/MoneyHelper';
import { DateHelper } from '../../../../../../helpers/DateHelper';
import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';

import { TaskPath } from '../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../projects/ProjectPath';
import { addItemsTableCellClassName } from '../../../../../items/utils/addItemsTableCellClassName';

import { InvoiceItemsGroupBy } from '../../../../invoicesConstants';

interface InvoiceItemsTableRowProps {
  currency?: CurrencyModalButtonItemType;
  item: InvoiceItemType;
  index: number;
  length: number;
  groupBy: Record<InvoiceItemsGroupBy, boolean>;
}

function InvoiceItemsTableRow({
  currency,
  item,
  index,
  length,
  groupBy
}: InvoiceItemsTableRowProps) {
  const currencyExchangeRate = currency?.exchangeRate || 1;
  const currencyPrefix =
    currenciesSymbols[currency?.currency] ||
    currency?.currency ||
    Currencies.USD;

  const entity = groupBy.tasks ? item.task : item.project;

  const entityName = groupBy.tasks ? (
    <NextPureLinkHelper
      className="hover:underline line-clamp-1 break-all"
      text={item.task?.name}
      href={TaskPath.show(item.task?.nanoId)}
    />
  ) : (
    <NextPureLinkHelper
      className="hover:underline line-clamp-1 break-all"
      text={item.project?.name}
      href={ProjectPath.messages(item.project?.nanoId)}
    />
  );

  return (
    <tr className="group align-top">
      <td
        className={cl(
          'text-xs text-left pr-0.5 sm:pr-2 break-wrap',
          addItemsTableCellClassName(index, length)
        )}
      >
        {groupBy.items ? item.itemTypeName : entityName}
      </td>

      <td
        className={cl(
          'text-xs text-left px-2',
          addItemsTableCellClassName(index, length)
        )}
      >
        {groupBy.items ? (
          <MoneyHelper
            value={item.price * currencyExchangeRate}
            currency={currencyPrefix}
          />
        ) : (
          entity?.owner?.fullName
        )}
      </td>

      <td
        className={cl(
          'text-xs text-right pl-2 relative pr-0 w-0',
          addItemsTableCellClassName(index, length)
        )}
      >
        {groupBy.items ? (
          item.quantity
        ) : (
          <DateHelper date={entity?.createdAt} />
        )}
      </td>

      <td
        className={cl(
          'text-xs text-right pl-2',
          addItemsTableCellClassName(index, length)
        )}
      >
        <MoneyHelper
          value={
            item.price *
            (groupBy.items ? item.quantity : 1) *
            currencyExchangeRate
          }
          currency={currencyPrefix}
        />
      </td>
    </tr>
  );
}

export default InvoiceItemsTableRow;
