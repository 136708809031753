import React, { Fragment, ReactNode } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import includes from 'lodash/includes';

import {
  InvoiceAmount,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceSmartContractProjectOwnerFullName,
  InvoiceSmartContractProjectOwnerNanoID,
  InvoiceSmartContractTaskCreatedAt,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskOwnerFullName,
  InvoiceSmartContractTaskOwnerNanoID,
  InvoiceSmartContractTaskProjectCreatedAt,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceStatus,
  InvoiceStatuses,
  InvoiceTotal
} from '../../invoicesTypes';
import { Currencies, I18nText, currenciesSymbols } from '../../../../types';
import { ItemPrice, ItemQuantity } from '../../../items/itemsTypes';
import { ItemTypeName } from '../../../itemTypes/itemTypesTypes';

import { InvoiceItemsTableRow } from './components/InvoiceItemsTableRow';

import { CurrencyModalButtonItemType } from '../../../../helpers/modalButtons/ChangeCurrencyModalButton/hooks/useChangeCurrencyModalButton';
import { MoneyHelper } from '../../../../helpers/MoneyHelper';
import { PercentHelper } from '../../../../helpers/PercentHelper';
import { Translate } from '../../../../helpers/Translate';

import {
  formsFields,
  invoicesKeys,
  itemsKeys,
  words
} from '../../../../locales/keys';
import {
  InvoiceItemsGroupBy,
  paidInvoiceStatuses
} from '../../invoicesConstants';

export type InvoiceItemType = {
  itemTypeName: ItemTypeName;
  price: ItemPrice;
  quantity: ItemQuantity;
  task?: {
    createdAt: InvoiceSmartContractTaskCreatedAt;
    nanoId: InvoiceSmartContractTaskNanoID;
    name: InvoiceSmartContractTaskName;
    owner: {
      nanoId: InvoiceSmartContractTaskOwnerNanoID;
      fullName: InvoiceSmartContractTaskOwnerFullName;
    };
  };
  project?: {
    createdAt: InvoiceSmartContractTaskProjectCreatedAt;
    nanoId: InvoiceSmartContractTaskProjectNanoID;
    name: InvoiceSmartContractTaskProjectName;
    owner: {
      nanoId: InvoiceSmartContractProjectOwnerNanoID;
      fullName: InvoiceSmartContractProjectOwnerFullName;
    };
  };
};

export type InvoiceItemGroup = {
  items: InvoiceItemType[];
  header?: ReactNode;
};

interface InvoiceItemsTableProps {
  currency?: CurrencyModalButtonItemType;
  groupBy?: Record<InvoiceItemsGroupBy, boolean>;
  amountDue: InvoiceAmount;
  invoiceTotal: InvoiceTotal;
  items: InvoiceItemGroup[];
  prepayment: InvoicePrepayment;
  prepaymentAmount?: InvoicePrepaymentAmount;
  payments?: number;
  i18nFirstColName?: I18nText;
  status: InvoiceStatus;
}

const defaultGroupBy = {
  [InvoiceItemsGroupBy.PROJECTS]: false,
  [InvoiceItemsGroupBy.TASKS]: false,
  [InvoiceItemsGroupBy.ITEMS]: true
};

function InvoiceItemsTable({
  currency,
  amountDue,
  invoiceTotal,
  items,
  prepayment,
  prepaymentAmount = 0,
  payments,
  i18nFirstColName,
  groupBy = defaultGroupBy,
  status
}: InvoiceItemsTableProps) {
  const currencyExchangeRate = currency?.exchangeRate || 1;
  const currencyPrefix =
    currenciesSymbols[currency?.currency] ||
    currency?.currency ||
    Currencies.USD;

  const tax = 0;

  return (
    <table className="w-full font-mono">
      {size(items) > 0 ? (
        <thead>
          <tr className="border-b text-gray-600 dark:text-gray-400 border-gray-200 dark:border-gray-600">
            <th className="text-xs font-semibold py-2 text-left pr-2 w-1/2">
              <div className="flex items-center gap-1.5">
                <Translate id={i18nFirstColName || itemsKeys.name} />
              </div>
            </th>
            <th className="text-xs font-semibold text-left py-2 pl-2 whitespace-nowrap">
              <Translate id={groupBy.items ? itemsKeys.price : words.owner} />
            </th>
            <th className="text-xs font-semibold text-left py-2 pl-2 whitespace-nowrap">
              <Translate
                id={groupBy.items ? itemsKeys.qty : itemsKeys.createDate}
              />
            </th>
            <th className="text-xs font-semibold py-2 text-right pl-2">
              <Translate
                id={groupBy.items ? itemsKeys.subTotal : words.total}
              />
            </th>
          </tr>
        </thead>
      ) : (
        <thead>
          <tr>
            <th className="pr-2 w-1/2"></th>
            <th className="px-2"></th>
            <th className="w-px"></th>
            <th className="pl-2"></th>
          </tr>
        </thead>
      )}

      <tbody>
        {size(items) > 0 ? (
          map(items, (group, key) => (
            <Fragment key={key}>
              {group.header && (
                <tr className="text-xs font-bold bg-gray-50 dark:bg-gray-850">
                  {group.header}
                </tr>
              )}

              {map(group.items, (item, index) => (
                <InvoiceItemsTableRow
                  currency={currency}
                  item={item}
                  index={index}
                  length={group.items.length}
                  groupBy={groupBy}
                  key={index}
                />
              ))}
            </Fragment>
          ))
        ) : (
          <tr>
            <td
              colSpan={4}
              className="text-md text-center text-gray-600 dark:text-gray-400 px-2 pb-10"
            >
              <Translate id={invoicesKeys.invoiceHasNoItems} />
            </td>
          </tr>
        )}
      </tbody>

      <tfoot>
        <tr>
          <td></td>
          <td
            colSpan={2}
            className="text-xs text-left px-2 pb-0.5 pt-2 border-t border-gray-200 dark:border-gray-600"
          >
            <Translate id={invoicesKeys.total} />
          </td>
          <td className="text-xs text-right pl-2 pb-0.5 pt-2 border-t border-gray-200 dark:border-gray-600">
            <MoneyHelper
              value={invoiceTotal * currencyExchangeRate}
              currency={currencyPrefix}
            />
          </td>
        </tr>

        {includes(paidInvoiceStatuses, status) ? (
          <>
            <tr>
              <td></td>
              <td colSpan={2} className="text-xs py-0.5 text-left px-2">
                <Translate id={words.payments} />
              </td>
              <td className="text-xs text-right pl-2 py-0.5">
                <MoneyHelper
                  value={-payments * currencyExchangeRate}
                  currency={currencyPrefix}
                />
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td></td>
              <td colSpan={2} className="text-xs py-0.5 text-left px-2">
                <Translate id={formsFields.prepayment} />
              </td>
              <td className="text-xs text-right pl-2 py-0.5">
                <PercentHelper percent={prepayment} />
              </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={2} className="text-xs py-0.5 text-left px-2">
                <Translate id={invoicesKeys.prepaymentAmount} />
              </td>
              <td className="text-xs text-right pl-2 py-0.5">
                <MoneyHelper
                  value={prepaymentAmount * currencyExchangeRate}
                  currency={currencyPrefix}
                />
              </td>
            </tr>
          </>
        )}

        <tr>
          <td></td>
          <td colSpan={2} className="text-xs pt-0.5 pb-2 text-left px-2">
            <Translate id={words.tax} />
          </td>
          <td className="text-xs text-right pl-2 pt-0.5 pb-2">
            <MoneyHelper
              value={tax * currencyExchangeRate}
              currency={currencyPrefix}
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td
            colSpan={2}
            className="sm:text-lg font-semibold text-left px-2 py-2 border-t border-gray-200 dark:border-gray-600"
          >
            <Translate id={words.amountDue} />
          </td>
          <td className="sm:text-lg text-right pl-0.5 sm:pl-2 font-semibold border-t border-gray-200 dark:border-gray-600 py-1.5">
            <div className="inline-block py-0.5 px-0.5 sm:px-2 bg-yellow-100 dark:bg-yellow-800 rounded-md">
              <MoneyHelper
                value={
                  (status === InvoiceStatuses.PAID
                    ? invoiceTotal - payments
                    : amountDue) * currencyExchangeRate
                }
                currency={currencyPrefix}
              />
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default InvoiceItemsTable;
