import React, { useCallback } from 'react';

import { InvoiceID } from '../../../invoicesTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useDownloadInvoice } from '../../../hooks/useDownloadInvoice';
// import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

interface DownloadInvoiceButtonProps {
  invoiceId: InvoiceID;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  disabled?: IsDisabled;
  className?: ClassName;
  tooltipI18nText?: I18nText;
}

function DownloadInvoiceButton({
  i18nText,
  icon,
  iconClassName,
  invoiceId,
  className,
  disabled,
  tooltipI18nText
}: DownloadInvoiceButtonProps) {
  const {
    // downloadInvoiceLoading,
    downloadInvoice
    // downloadInvoiceErrorMessage
  } = useDownloadInvoice();

  // useShowToastOnErrorChange({ error: downloadInvoiceErrorMessage });

  const handelDownloadInvoice = useCallback<() => void>(
    () => downloadInvoice({ invoiceId }),
    [invoiceId, downloadInvoice]
  );

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      disabled={disabled}
      // disabled={downloadInvoiceLoading || disabled}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName || 'h-5 w-5 mr-2'}
      onClick={handelDownloadInvoice}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default DownloadInvoiceButton;
